import { message } from "utils/format";

export default error => {
  const { status, config, data } = error;
  const isLogin = config.url.includes("auth/web/login") || config.url.includes("auth/login");
  const isForgotPassword = config.url.includes("auth/web/password/forgot") || config.url.includes("auth/password/forgot");
  const authErrorMsg = (isLogin || isForgotPassword)
    ? data.message
    : "Your session has expired. Please login again to continue working.";

  switch (status) {
    case 400: {
      return message(
        "Sorry, something went wrong. Please, try again.",
        "error"
      );
    }

    case 401: {
      return message(authErrorMsg, "error");
    }

    default: {
      message(data.message, "error");
    }
  }
};
