import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Modal from "components/Modal";
import Div from "components/Div";
import P from "components/P";
import { Button } from "components/actions";
import { useModalWithValidation } from "components/hooks";
import MetadataList from "./MetadataList";
import MetadataForm from "./MetadataForm";

const Metadata = ({
  parentStore,
  parentName,
  rootStore
}) => {
  const { metadataItem, addMetadata, resetMetadata } = parentStore;
  const [
    isEditModalShown,
    setEditModalShown,
    onEditModalOk,
    onEditModalCancel
  ] = useModalWithValidation(rootStore);

  return (
    <Div>
      <Modal
        title={`Please, create an extension`}
        showModal={isEditModalShown}
        confirmButtonTitle="Add"
        onCancel={onEditModalCancel(resetMetadata)}
        onConfirm={onEditModalOk(addMetadata)}
      >
        <Div width="60%">
          <MetadataForm metadata={metadataItem} />
        </Div>
      </Modal>
      <Div direction="column" width="620px">
        <P size={16} weight="bold">
          Extensions
        </P>
        <P bottom={12} width="400px">
          Add extension to be associated with this {parentName}.
        </P>
        <Div>
          <Button type="purpleUnderlined" onClick={setEditModalShown(() => {})}>
            + Add extension
          </Button>
        </Div>
        <Div top={12}>
          <MetadataList parentStore={parentStore} />
        </Div>
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore: rootStore
  })),
  observer
)(Metadata);
