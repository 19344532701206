import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Div from "components/Div";
import Form from "components/Form";
import { Button, Link } from "components/actions";
import { authForm } from "./settings";
import {
  StyledInput,
  StyledEmailPrefixIcon,
  StyledPasswordPrefixIcon,
  StyledCompanyCodePrefixIcon,
  StyledPostfixIcon,
  StyledWarningText,
} from "./Styled";

const Login = ({ authStore, rootStore, themeStore }) => {
  useEffect(() => {
    authStore.reset();
  }, [authStore]);

  const onSubmit = (e) => e.key === "Enter" && authStore.login();

  return (
    <Form direction="column" align="center" width="325px">
      <Div width="100%" bottom={40} top={20}>
        <StyledEmailPrefixIcon
          isValid={!rootStore.validationErrors.email}
          theme={themeStore.theme}
        />
        <StyledInput
          name="email"
          value={authStore.email}
          placeholder={authForm.emailPlaceholder}
          onChange={authStore.change}
          onKeyPress={onSubmit}
          isValid={!rootStore.validationErrors.email}
          theme={themeStore.theme}
        />
        {rootStore.validationErrors.email ? (
          <StyledPostfixIcon theme={themeStore.theme} />
        ) : null}
        <StyledWarningText
          isValid={!rootStore.validationErrors.email}
          theme={themeStore.theme}
        >
          {authForm.emailErrorMessage}
        </StyledWarningText>
      </Div>
      <Div width="100%" bottom={40}>
        <StyledPasswordPrefixIcon
          isValid={!rootStore.validationErrors.password}
          theme={themeStore.theme}
        />
        <StyledInput
          name="password"
          type="password"
          value={authStore.password}
          placeholder={authForm.passwordPlaceholder}
          onChange={authStore.change}
          onKeyPress={onSubmit}
          isValid={!rootStore.validationErrors.password}
          theme={themeStore.theme}
        />
        {rootStore.validationErrors.password ? (
          <StyledPostfixIcon theme={themeStore.theme} />
        ) : null}
        <StyledWarningText
          isValid={!rootStore.validationErrors.password}
          theme={themeStore.theme}
        >
          {authForm.passwordErrorMessage}
        </StyledWarningText>
      </Div>
      <Div width="100%" bottom={40}>
        <StyledCompanyCodePrefixIcon
          isValid={!rootStore.validationErrors.companyCode}
          theme={themeStore.theme}
        />
        <StyledInput
          name="companyCode"
          maxLength="6"
          value={authStore.companyCode}
          placeholder={authForm.companyCodePlaceholder}
          onChange={authStore.change}
          onKeyPress={onSubmit}
          isValid={!rootStore.validationErrors.companyCode}
          theme={themeStore.theme}
        />
        {rootStore.validationErrors.companyCode ? (
          <StyledPostfixIcon theme={themeStore.theme} />
        ) : null}
        <StyledWarningText
          isValid={!rootStore.validationErrors.companyCode}
          theme={themeStore.theme}
        >
          {authForm.companyCodeErrorMessage}
        </StyledWarningText>
      </Div>
      <Div width="100%" bottom={50}>
        <Button type="auth" onClick={authStore.login}>
          Login
        </Button>
      </Div>
      <Div width="100%" justify="center">
        <Link type="authLink" to="/forgot-password">
          Forgot Password?
        </Link>
      </Div>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    authStore: rootStore.authStore,
    rootStore,
  })),
  observer
)(Login);
