import axios from "axios";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";

import errorMessageHandler from "./error-handler";
import urls from "./api-urls";
import ls from "utils/local-storage";

const {
  REACT_APP_LS_CONTEXT_COMPANY_KEY,
  REACT_APP_LS_REFRESH_TOKEN_KEY,
  REACT_APP_LS_TOKEN_KEY,
  REACT_APP_SERVER_HOST,
} = process.env;

class Api {
  allowedMethods = ["get", "post", "put", "delete"];
  errorMessageHandler = errorMessageHandler;
  cancelRequest = null;
  version = "";

  async getContext() {
    return await ls.get(REACT_APP_LS_CONTEXT_COMPANY_KEY);
  }

  validateUrl = url => {
    if (!isString(url)) {
      throw new Error(`{url} parameter must be a string, got a ${typeof url}`);
    }
  };

  validateHeaders = headers => {
    if (!isObject(headers)) {
      throw new Error(
        `{headers} parameter must be a valid object, got a ${typeof headers}`,
      );
    }
  };

  validateMethod = method => {
    if (!this.allowedMethods.includes(method)) {
      throw new Error(
        `Method ${method} is not allowed. Please, use one of ${this.allowedMethods}.`,
      );
    }
  };

  trimEndSlash = s => s.replace(/\/$/, "");

  combineUrl = url => {
    const host = this.trimEndSlash(REACT_APP_SERVER_HOST);
    const initUrl = `${host}/${url}`;
    const defaultUrl = `${host}/${this.version}/${url}`;

    return isEmpty(this.version) ? initUrl : defaultUrl;
  };

  getApiVersion = async () => {
    const { method, url } = urls.api.getVersion;
    const requestUrl = this.combineUrl(url);
    const authorization = await this.getAuthorizationHeader();
    const { data: response } = await axios({
      url: requestUrl,
      method,
      headers: { ...authorization },
    });

    this.version = response.data.api;

    return response;
  };

  getAccessToken = async () => {
    const { token: contextToken } = await ls.get(
      REACT_APP_LS_CONTEXT_COMPANY_KEY,
      {},
    );
    const savedToken = await ls.get(REACT_APP_LS_TOKEN_KEY);

    return contextToken ? contextToken : savedToken;
  };

  getRefreshToken = async () => {
    return await ls.get(REACT_APP_LS_REFRESH_TOKEN_KEY);
  };

  getAuthorizationHeader = async token => {
    const savedToken = await this.getAccessToken();
    return {
      Authorization: `Bearer ${token || savedToken}`,
    };
  };

  refreshToken = async () => {
    const { method, url } = urls.refreshToken;
    const requestUrl = this.combineUrl(url);
    const authorization = await this.getAuthorizationHeader();
    const refresh = await this.getRefreshToken();

    const { data: response } = await axios({
      url: requestUrl,
      method,
      headers: { ...authorization },
      data: { refresh },
    });

    return { response };
  };

  onAuthError = async error => {
    const rejectedRequest = error.config;
    const { response: errorEntity } = error;
    const isAuthError = errorEntity.status === 401;
    const isConstraintError = errorEntity.status === 403;
    const isRefreshError = rejectedRequest.url.includes("auth/refresh");
    const isLogin = rejectedRequest.url.includes("auth/login") || rejectedRequest.url.includes("auth/web/login");
    const isForgotPassword = rejectedRequest.url.includes("auth/password/forgot") || rejectedRequest.url.includes("auth/web/password/forgot");

    if (isConstraintError) return;

    if (isAuthError && isRefreshError && !isLogin && !isForgotPassword) {
      this.errorMessageHandler(errorEntity);
      return setTimeout(() => this.logout(), 300);
    }

    if (isAuthError && !isLogin && !isRefreshError && !isForgotPassword) {
      const { response } = await this.refreshToken();

      if (response) {
        const { tokens } = response.data;

        rejectedRequest.headers = await this.getAuthorizationHeader(
          tokens.access,
        );
        rejectedRequest.data = JSON.parse(rejectedRequest.data);

        const context = await this.getContext();
        await Promise.all([
          ls.save(
            context ? REACT_APP_LS_CONTEXT_COMPANY_KEY : REACT_APP_LS_TOKEN_KEY,
            context ? { ...context, token: tokens.access } : tokens.access,
          ),
          ls.save(REACT_APP_LS_REFRESH_TOKEN_KEY, tokens.refresh),
        ]);
        return axios(rejectedRequest);
      }
    }

    this.errorMessageHandler(errorEntity);
  };

  buildRequest = async ({
    method,
    url,
    data = {},
    headers = {},
    params,
    useExternalUrl = false,
    responseType
  }) => {
    this.validateMethod(method);
    this.validateUrl(url);
    this.validateHeaders(headers);
    const CancelToken = axios.CancelToken;
    const requestUrl = useExternalUrl ? url : this.combineUrl(url);
    const authorization = await this.getAuthorizationHeader();
    const requestHeaders = useExternalUrl
      ? headers
      : {
          ...headers,
          ...authorization,
        };

    return {
      url: requestUrl,
      method,
      data,
      headers: requestHeaders,
      params,
      cancelToken: new CancelToken(c => (this.cancelRequest = c)),
      responseType
    };
  };

  sendRequest = async data => {
    const request = await this.buildRequest(data);

    try {
      axios.interceptors.response.use(null, this.onAuthError);
      const response = await axios(request);

      return { data: response ? response.data : null };
    } catch (e) {
      return { error: e };
    }
  };

  logout = async () => {
    await Promise.all([
      ls.remove(REACT_APP_LS_TOKEN_KEY),
      ls.remove(REACT_APP_LS_CONTEXT_COMPANY_KEY),
      ls.remove(REACT_APP_LS_CONTEXT_COMPANY_KEY),
    ]);
    // todo: find a better way how to redirect after logout
    window.location.reload();
  };

  abort = () => {
    if (this.cancelRequest) {
      this.cancelRequest();
    }
    return null;
  };
}

export default new Api();
