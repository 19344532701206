import isUUID from "validator/es/lib/isUUID";
import isURL from "validator/es/lib/isURL";
import isEmail from "validator/es/lib/isEmail";
import { isString, keys, isArray, isEmpty } from "lodash";

import { rootStore } from "index";
import Company from "../models/Company";

class Validator {
  urlOptions = {
    protocols: ["http", "https"],
    require_tld: true,
    require_protocol: true,
    require_host: true,
    require_valid_protocol: true,
    allow_underscores: false,
    host_whitelist: false,
    host_blacklist: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false,
    disallow_auth: false
  };

  isValidEmail(value) {
    if (!isString(value)) return false;
    return !isEmail(value);
  }

  isRequired(value, arr) {
    return arr.includes(value);
  }

  isValidCompanyCode(value) {
    if(!value) return false
    return !/^\d{1,6}$/.test(value);
  }

  isUrl(value) {
    return !isURL(value, this.urlOptions);
  }

  isUuid(value) {
    return !isUUID(value, 4);
  }

  isCompanyInstance(company) {
    return !(company instanceof Company && company.id);
  }

  isStringWithProperLen(value = "", min = 1, max = 50) {
    if (!isString(value)) return false;
    const string = value.trim();
    return !(string.length >= min && string.length <= max);
  }

  isTwoStringsEqual(str1, str2) {
    return !!str1.localeCompare(str2);
  }

  isValidSequenceSteps(steps) {
    return steps.map(s => {
      if ("url" in s) {
        return this.isUrl(s.url);
      }
      return this.isUuid(s.experienceId);
    });
  }

  isValidMenuSteps(steps) {
    return steps.map(({ buttonName, url, buttonIcon }) => {
      const hasIcon = buttonIcon && !isEmpty(buttonIcon);
      const hasButtonName = !this.isStringWithProperLen(buttonName, 1, 50);
      const isContentValid = !(hasIcon || hasButtonName);

      return {
        buttonName: isContentValid,
        url: this.isUrl(url),
        buttonIcon: isContentValid
      };
    });
  }

  hasValidRole(roles, role) {
    return roles.includes(role);
  }

  isValidFileSize(size, maxSize = 5242880) {
    return size < maxSize;
  }

  isValidQuantity(qty, maxQty = 12) {
    return qty < maxQty;
  }

  isValidName(name) {
    return !name.startsWith("$");
  }

  isValidDimension(imageDimension, validationDimension) {
    return imageDimension <= validationDimension;
  }

  async getImageDimension(file) {
    return new Promise((resolve) => {
      const fr = new FileReader();
      fr.onload = () => {
        // file is loaded
        const img = new Image();
        img.onload = () =>{
          resolve({
            width: img.width,
            height: img.height
          })
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(file);
    })
  }

  mapResult(result) {
    return keys(result).reduce(
      (acc, key) => (result[key] ? { ...acc, [key]: result[key] } : acc),
      {}
    );
  }

  validateSequence({ name = "", description = "", steps = [] }) {
    const result = {
      name: this.isStringWithProperLen(name),
      description: this.isStringWithProperLen(description, 0, 250),
      steps: this.isValidSequenceSteps(steps)
    };

    return keys(result).reduce((acc, key) => {
      const hasError = isArray(result[key])
        ? result[key].find(v => v === true) || !result[key].length
        : result[key];

      return hasError ? { ...acc, [key]: result[key] } : acc;
    }, {});
  }

  validateMenu({ name = "", description = "", steps = [], templateId = "" }) {
    const result = {
      name: this.isStringWithProperLen(name),
      description: this.isStringWithProperLen(description, 0, 250),
      steps: this.isValidMenuSteps(steps),
      templateId: this.isUuid(templateId)
    };

    return keys(result).reduce((acc, key) => {
      const hasError = isArray(result[key])
        ? result[key].find(
            ({ buttonName, url }) => buttonName === true || url === true
          ) || !result[key].length
        : result[key];

      return hasError ? { ...acc, [key]: result[key] } : acc;
    }, {});
  }

  validateLot({ name = "", experienceId = "", description = "" }) {
    return this.mapResult({
      // todo: add validation for status which optional field
      name: this.isStringWithProperLen(name),
      // todo: make common optional value
      experienceId: experienceId ? this.isUuid(experienceId) : false,
      description: this.isStringWithProperLen(description, 0, 250)
    });
  }

  validateCompany(data) {
    const {
      id = "",
      name = "",
      address1 = "",
      address2 = "",
      city = "",
      state = "",
      country = "",
      zip = "",
      contactName = "",
      contactEmail = "",
      contactPhone = "",
      description = "",
      constraintId = ""
    } = data;
    const requiredFields = ["name", "constraintId"];

    const result = {
      id: this.isUuid(id),
      name: this.isStringWithProperLen(name),
      address1: this.isStringWithProperLen(address1),
      address2: this.isStringWithProperLen(address2),
      city: this.isStringWithProperLen(city),
      state: this.isStringWithProperLen(state),
      country: this.isStringWithProperLen(country),
      zip: this.isStringWithProperLen(zip, 3, 14),
      contactName: this.isStringWithProperLen(contactName),
      contactEmail: this.isValidEmail(contactEmail),
      contactPhone: this.isStringWithProperLen(contactPhone),
      description: this.isStringWithProperLen(description, 0, 250),
      constraintId: this.isUuid(constraintId)
    };

    return keys(result).reduce((acc, key) => {
      // todo: make common optional value and make it better
      if (!this.isRequired(key, requiredFields) && !data[key]) {
        return acc;
      }

      if (result[key]) {
        return { ...acc, [key]: result[key] };
      }

      return acc;
    }, {});
  }

  validateProfile(data) {
    const {
      firstName = "",
      lastName = "",
      email = "",
      phone = "",
      job = "",
      country = ""
    } = data;
    const requiredFields = ["firstName", "lastName", "email"];

    const result = {
      firstName: this.isStringWithProperLen(firstName),
      lastName: this.isStringWithProperLen(lastName),
      email: this.isStringWithProperLen(email),
      phone: this.isStringWithProperLen(phone),
      job: this.isStringWithProperLen(job),
      country: this.isStringWithProperLen(country)
    };

    return keys(result).reduce((acc, key) => {
      // todo: make common optional value and make it better
      if (!this.isRequired(key, requiredFields) && !data[key]) {
        return acc;
      }

      if (result[key]) {
        return { ...acc, [key]: result[key] };
      }

      return acc;
    }, {});
  }

  validateTemplate({ name = "", description = "" }) {
    return this.mapResult({
      name: this.isStringWithProperLen(name),
      description: this.isStringWithProperLen(description, 0, 250)
    });
  }

  async validateUsers(
    { firstName = "", lastName = "", email = "", companyId },
    isEdit,
    users
  ) {
    const result = this.mapResult({
      firstName: this.isStringWithProperLen(firstName),
      lastName: this.isStringWithProperLen(lastName),
      email: isEdit ? false : this.isValidEmail(email)
    });

    if (isEdit) {
      return result;
    }

    if (!result.email) {
      const partialErrorData = { ...result, email: true };
      const emailExistsInUsers = users.some(user => user.email === email);

      if (emailExistsInUsers) return { ...result, ...partialErrorData };

      const { method, url } = rootStore.urls.users.isUniqEmail;
      const { response } = await rootStore.makeRequest({
        method,
        url: `${url}?email=${encodeURIComponent(email)}&companyId=${encodeURIComponent(companyId)}`
      });

      if (response && !response.data.unique)
        return { ...result, ...partialErrorData };
    }

    return result;
  }

  validateLogin({ email = "", password = "", companyCode = "" }) {
    return this.mapResult({
      email: this.isValidEmail(email),
      password: this.isStringWithProperLen(password, 8),
      companyCode: this.isValidCompanyCode(companyCode)
    });
  }

  validateForgotPassword({ email = "", companyCode = "" }) {
    return this.mapResult({
      email: this.isValidEmail(email),
      companyCode: this.isValidCompanyCode(companyCode)
    });
  }

  validateCreateSubRoll({ rangeStart = "", rangeEnd = "", roll = "" }) {
    return this.mapResult({
      rangeStart: this.isStringWithProperLen(rangeStart, 32, 36),
      rangeEnd: this.isStringWithProperLen(rangeEnd, 32, 36),
      roll: this.isUuid(roll)
    });
  }

  validateSwitchContext({ companyId = "" }) {
    return this.mapResult({
      companyId: this.isUuid(companyId)
    });
  }

  validateCompanyAssign(company) {
    return this.mapResult({
      company: this.isCompanyInstance(company)
    });
  }

  validateChangePassword({ oldPassword, newPassword, repeatPassword }) {
    return this.mapResult({
      oldPassword: this.isStringWithProperLen(oldPassword, 8),
      newPassword: this.isStringWithProperLen(newPassword, 8),
      repeatPassword: this.isTwoStringsEqual(newPassword, repeatPassword)
    });
  }

  validateBase64(data) {
    const base64regex = new RegExp(/data:image\/png;base64,/, "gim");
    return base64regex.test(data);
  }

  validatePermissions(routeRoles, userRole, isInContext) {
    if (isInContext) {
      return this.hasValidRole(routeRoles, "company_admin");
    }
    return this.hasValidRole(routeRoles, userRole);
  }

  validateRoll({ description = "" }) {
    return this.mapResult({
      description: this.isStringWithProperLen(description, 0, 250)
    });
  }

  validateForgotPasswordUpdate({ password, confirmPassword }) {
    return this.mapResult({
      password: this.isStringWithProperLen(password, 8),
      confirmPassword: this.isTwoStringsEqual(password, confirmPassword)
    });
  }

  async validateAsset({ asset, maxAssetSize, qty, maxQty, dimension, name }) {
    const validationResult = {
      name: !this.isValidName(asset.name),
      size: !this.isValidFileSize(asset.size, maxAssetSize),
      quantity: !this.isValidQuantity(qty, maxQty)
    }

    if (dimension) {
      const imageDimension = await this.getImageDimension(asset)
      if (dimension.width) {
        validationResult.width = !this.isValidDimension(imageDimension.width, dimension.width) && {
          name,
          dimension:  dimension.width
        }
      }
      if (dimension.height) {
        validationResult.height = !this.isValidDimension(imageDimension.height, dimension.height) && {
          name,
          dimension:  dimension.height
        }
      }
    }

    return this.mapResult(validationResult);
  }

  validateMetadata({ name = "", value = "", metadata = {} }) {
    return this.mapResult({
      name: this.isStringWithProperLen(name, 1, 64) || name in (metadata || {}) || !this.isValidName(name),
      value: this.isStringWithProperLen(value, 1, 256)
    });
  }
}

export default new Validator();
